import "./App.css";
import { Route, HashRouter as Router, Routes, Navigate } from "react-router-dom";
import Home from "./views/Home";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ProductDetail from "./views/ProductDetail";
import Login from "./views/Login";
import BankingForm from "./components/Form/BankingForm";
import Navbar from "./components/Custom/Navbar";
import TableData from "./components/TableData";
import Footer from "./components/Custom/Footer";
import BlogDetail from "./components/Blog/BlogDetail";
import { useState } from "react";
import About from "./views/About";
import ContactUs from "./views/Contact";
import BlogSection from "./components/Blog/BlogSection";
import ProfilePage from "./views/ProfilePage";
import LeadsTable from "./components/LeadsTable";
import AdminLogin from "./views/AdminLogin";
import ProtectedRoutes from "./ProtectedRoutes";
import { Toaster } from "react-hot-toast";
import { makeStyles } from "@mui/styles";
import EnquiryTable from "./components/EnquiryTable";

const theme = createTheme({
  palette: {
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#000",
    },
  },
  margin: 0,
  padding: 0,
});

const useStyles = makeStyles({
  "@global": {
    body: {
      "--sb-track-color": "#FFFFFF",
      "--sb-thumb-color": "#0561AB",
      "--sb-size": "6px",
    },
    "body::-webkit-scrollbar": {
      width: "var(--sb-size)",
    },
    "body::-webkit-scrollbar-track": {
      background: "var(--sb-track-color)",
      borderRadius: "3px",
    },
    "body::-webkit-scrollbar-thumb": {
      background: "var(--sb-thumb-color)",
      borderRadius: "3px",
    },
    "@supports not selector(::-webkit-scrollbar)": {
      body: {
        scrollbarColor: "var(--sb-thumb-color) var(--sb-track-color)",
      },
    },
  },
});

function App() {
  const [isDepositClicked, setIsDepositClicked] = useState(false);
  useStyles();

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") ? true : false
  );

  const [role, setRole] = useState(localStorage.getItem("role") );


  return (
    <ThemeProvider theme={theme}>
      <Toaster position="top-right" reverseOrder={false} />
      <Router>
        <Navbar
          isLoggedIn={isLoggedIn}
          role={role}
          setIsLoggedIn={setIsLoggedIn}
          setIsDepositClicked={setIsDepositClicked}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                isDepositClicked={isDepositClicked}
                setIsDepositClicked={setIsDepositClicked}
              />
            }
          />
          <Route
            path="/login"
            element={<Login setIsLoggedIn={setIsLoggedIn} />}
          />
          <Route 
            path="/profile" 
            element={
              <ProtectedRoutes requiredRole="User">
                <ProfilePage />
              </ProtectedRoutes>
            }
          />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route
            path="/admin/dashboard"
            element={
              <ProtectedRoutes requiredRole="Admin">
                <TableData />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/admin/dashboard/profile/:id"
            element={
              <ProtectedRoutes requiredRole="Admin">
                <ProfilePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/admin/dashboard/leads"
            element={
              <ProtectedRoutes requiredRole="Admin">
                <LeadsTable />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/admin/dashboard/enquries"
            element={
              <ProtectedRoutes requiredRole="Admin">
                <EnquiryTable />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/admin/user/create"
            element={
              <ProtectedRoutes requiredRole="Admin">
                <ProfilePage />
              </ProtectedRoutes>
            }
          />
          <Route path="/form" element={<BankingForm />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/blogs" element={<BlogSection />} />
          <Route path="/:product" element={<ProductDetail />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          
          <Route
            path="*"
            element={<Navigate to="/" />}
          />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
