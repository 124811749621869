import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  Avatar,
  Box,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AOS from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    padding: theme.spacing(2)
  },
  content: {
    textAlign: "start",
    padding: "0 10%",
    "@media (max-width: 600px)": {
      padding: "0 5%",
    },
  },
  heading: {
    fontFamily: "sans-serif",
    marginBottom: theme.spacing(4),
    color: "#0047AB",
    fontWeight: "600",
    fontSize: "2.5rem !important",
    fontStyle: "normal",
    "@media (max-width: 600px)": {
      fontSize: "1.7rem !important",
    },
  },
  paragraph: {
    fontFamily: "sans-serif",
    textIndent: "50px",
    textAlign: "justify",
    fontSize: "1.2rem",
    marginBottom: theme.spacing(2),
    color: "#000000",
    "@media (max-width: 600px)": {
      fontSize: "1rem",
    },
  },
  button: {
    borderRadius: "50px",
    padding: theme.spacing(1.5, 3),
    color: "#ffffff",
    backgroundColor: "#ff4081",
    "&:hover": {
      backgroundColor: "#f50057",
    },
    marginTop: theme.spacing(2),
  },
  imagesContainer: {
    textAlign: "center",
  },
  mainImage: {
    width: "60%",
    padding: theme.spacing(3),
    "@media (max-width: 600px)": {
      width: "100%",
      padding: theme.spacing(1),
    },
  },
  kpiSection: {
    padding: theme.spacing(3, 0),
    // margin: "20px 0",
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "space-around",
    textAlign: "center",
    backgroundImage: "linear-gradient(to right, #e0f7fa, #fffde7, #ffebee)",
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    "@media (max-width: 600px)": {
      flexDirection: "column",
      padding: theme.spacing(2),
    },
  },
  kpiItem: {
    flex: 1,
    padding: theme.spacing(4),
    borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
    "&:first-child": {
      borderLeft: "none",
    },
    "@media (max-width: 600px)": {
      borderLeft: "none",
      padding: theme.spacing(2, 0),
    },
  },
  kpiNumber: {
    fontSize: "26px",
    fontFamily: "sans-serif",
    fontWeight: "bold",
    color: "#333",
  },
  kpiLabel: {
    fontSize: "16px",
    color: "#555",
  },
  ctaSection: {
    padding: "30px 2%",
  },
  ctaImage: {
    width: "100%",
    height: "240px",
    objectFit: "cover",
    borderRadius: "8px",
  },
  teamSection: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(4, 2),
    // backgroundColor: "#E8EAF1",
  },
  teamItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  teamImage: {
    width: "100%",
    height: "300px",
    objectPosition: "top",
    border: "10px solid #F35425",
    objectFit: "cover",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  teamText: {
    marginTop: theme.spacing(-7),
    background: "#FFFFFF",
    border: "17px solid #efefef",
    borderRadius: "30%",
    gap: 3,
    padding: theme.spacing(2),
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    "@media (max-width: 600px)": {
      width: "130px",
    },
  },
  teamName: {
    fontSize: "0.9rem",
    color: "#F35425",
    fontFamily: "sans-serif",
    fontWeight: "600",
    "@media (max-width: 600px)": {
      fontSize: "0.5rem",
    },
  },
  gallerySection: {
    padding: theme.spacing(4, 2),
    background: "#ffffff",
    background:
      "linear-gradient(135deg, rgba(255, 255, 255, 1) 25%, rgba(230, 242, 255, 0.1) 25%, rgba(230, 242, 255, 0.1) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 75%, rgba(230, 242, 255, 0.2) 75%, rgba(230, 242, 255, 0.1))",
    backgroundSize: "40px 40px",
    animation: "$move 4s linear infinite",
    color: "#fff",
    "@media (max-width: 600px)": {
      padding: "0",
    },
  },
  galleryItem: {
    position: "relative",
    overflow: "hidden",
    width: "250px", // Set a fixed width for the gallery items
    height: "350px", // Set a fixed height for the gallery items
    "@media (max-width: 600px)": {
      width: "100%",
      height: "350px",
    },
    "&:hover img, &:hover video": {
      transform: "scale(1.1)",
    },
  },
  galleryImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "bottom", // Ensures the image covers the area without distortion
    transition: "transform 0.5s ease",
  },
  galleryGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", // Ensure the grid adjusts to screen size
    gap: theme.spacing(2),
  },
  "@keyframes move": {
    "0%": {
      backgroundPosition: "0 0",
    },
    "100%": {
      backgroundPosition: "40px 40px",
    },
  },
}));
const AboutUs = () => {
  const classes = useStyles();

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <Box className={classes.root}>
      {/* About Us Section */}
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12} md={6} data-aos="fade-up">
          <div className={classes.content}>
            <Typography variant="h2" mb={3} className={classes.heading}>
              About Us
            </Typography>

            <Typography mb={2} variant="body1" className={classes.paragraph}>
              Our commitment is to get member rewarded with assured return and
              to provide the fair value of the risk that investor is taking
              irrespective of market circumstances. Simultaneously we promote
              the habit of saving and investment in society. We always look for
              improvement in the economy and get business as well as individual
              funded smoothly with hassle-free Financial Services. Like the
              Circulation of blood in the body helps to keep humans alive and
              healthy, the Circulation of money in economy keeps country growing
              GDP.
            </Typography>
            <Typography mb={2} variant="body1" className={classes.paragraph}>
              We bring you top class Money Market Investment products including
              Fixed Deposit, Recurring Deposit, and Monthly Income scheme that
              offer a return from 9.00 % to 12.00 % per annum. You can browse,
              select, and invest of your choice, and that all through our online
              platform. Investing in fixed income investment instruments through
              Credit Co-Operative Society was never easier!.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <div className={classes.imagesContainer}>
            <img
              src={"mobile.svg"}
              alt="About 1"
              className={classes.mainImage}
            />
          </div>
        </Grid>
      </Grid>

      {/* KPIs Section */}
      <Box className={classes.kpiSection}>
        <div className={classes.kpiItem}>
          <p variant="h6" className={classes.kpiNumber}>
            25 Mn+
          </p>
          <Typography variant="body2" className={classes.kpiLabel}>
            Active Members
          </Typography>
        </div>
        <div className={classes.kpiItem}>
          <p variant="h6" className={classes.kpiNumber}>
            6 Mn+
          </p>
          <Typography variant="body2" className={classes.kpiLabel}>
            Deposits
          </Typography>
        </div>
        <div className={classes.kpiItem}>
          <p variant="h6" className={classes.kpiNumber}>
            ₹20000+ Cr
          </p>
          <Typography variant="body2" className={classes.kpiLabel}>
            Loan Disbursed
          </Typography>
        </div>
        <div className={classes.kpiItem}>
          <p variant="h6" className={classes.kpiNumber}>
            5000+
          </p>
          <Typography variant="body2" className={classes.kpiLabel}>
            App Downloads
          </Typography>
        </div>
      </Box>

      {/* Call To Action Section */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }} mt={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} minWidth={'50%'} data-aos="fade-up">
            <img
              src={"vision.avif"}
              alt="Call To Action"
              className={classes.ctaImage}
            />
          </Grid>
          <Grid item xs={12} md={6} data-aos="fade-up">
            <Box className={classes.content} p={1}>
              <Typography
                variant="h2"
                className={classes.heading}
                mb={2}
                style={{ color: "#F35425" }}
              >
                Our Vision
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Our vision is to create an advanced Fintech platform that
                seamlessly integrates finance, investment, and wealth management
                services. By leveraging a panel of experienced professionals, we
                aim to provide personalized guidance and innovative tools to
                foster a culture of saving and investing within the community.
                Our platform will empower users to achieve their financial
                goals, secure their future, and make informed investment
                decisions, ultimately enhancing financial literacy and stability
                in society.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} minWidth={'50%'} data-aos="fade-up">
            <Box className={classes.content} p={1}>
              <Typography
                variant="h2"
                className={classes.heading}
                style={{ color: "#F35425" }}
                mb={2}
              >
                Our Mission
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Our mission is to maximize investor returns and minimize
                potential losses by adhering to a disciplined and pragmatic
                approach. We prioritize factual data over speculation, employing
                rigorous research and analysis to identify high-return
                opportunities. By leveraging advanced analytics, market
                insights, and a diverse range of investment options, we aim to
                provide our clients with robust, evidence-based strategies. Our
                commitment to transparency, integrity, and continuous
                improvement ensures that our clients' investments are managed
                with the utmost care and expertise, fostering long-term
                financial growth and security.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} minWidth={'50%'} data-aos="fade-up">
            <img
              src={"mission.jpeg"}
              alt="Call To Action"
              className={classes.ctaImage}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Our Team Section */}
      <Box className={classes.teamSection}>
        <Typography
          variant="h2"
          mb={3}
          className={classes.heading}
          color="black"
          fontWeight={"bold"}
          align="center"
          data-aos="fade-up"
        >
          Our Team
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} data-aos="fade-up">
            <div className={classes.teamItem}>
              <img
                src={"/team/Kaushal.jpeg"}
                alt="Team 1"
                className={classes.teamImage}
              />
              <div className={classes.teamText}>
                <p className={classes.teamName}>Kaushal Gajjar</p>
                <p className={classes.teamName} style={{ color: "black" }}>
                  - Founder
                </p>{" "}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} data-aos="fade-up">
            <div className={classes.teamItem}>
              <img
                src={"/team/krunal.jpg"}
                alt="Team 2"
                className={classes.teamImage}
              />
              <div className={classes.teamText}>
                <p className={classes.teamName}>Krunal Gajjar</p>
                <p className={classes.teamName} style={{ color: "black" }}>
                  - Co founder
                </p>{" "}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} data-aos="fade-up">
            <div className={classes.teamItem}>
              <img
                src={"/team/Krishna.jpeg"}
                alt="Team 3"
                className={classes.teamImage}
              />
              <div className={classes.teamText}>
                <p className={classes.teamName}>Krishna Gajjar</p>
                <p className={classes.teamName} style={{ color: "black" }}>
                  - General Manager
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>

      {/* Photo Gallery Section */}
      <Box className={classes.gallerySection}>
        <Typography
          className={classes.heading}
          align="center"
          mb={5}
          style={{
            fontSize: "2.5rem",
            color: "#000",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          Photo Gallery
        </Typography>
        <Box className={classes.galleryGrid} data-aos="fade-up">
          {/* <div className={classes.galleryItem}>
            <video
              src={"gallary/0.mp4"}
              autoPlay
              muted
              loop
              className={classes.galleryImage}
            />

          </div> */}
          <div className={classes.galleryItem}>
            <img
              src={"gallary/1.jpg"}
              alt="Gallery 1"
              className={classes.galleryImage}
            />
          </div>
          <div className={classes.galleryItem}>
            <img
              src={"gallary/2.jpg"}
              alt="Gallery 2"
              className={classes.galleryImage}
            />
          </div>
          <div className={classes.galleryItem}>
            <img

              src={"gallary/3.jpg"}
              alt="Gallery 3"
              className={classes.galleryImage}
            />
          </div>
          <div className={classes.galleryItem}>
            <img

              src={"gallary/4.jpg"}
              alt="Gallery 4"
              className={classes.galleryImage}
            />
          </div>
          <div className={classes.galleryItem}>
            <img

              src={"gallary/5.jpg"}
              alt="Gallery 5"
              className={classes.galleryImage}
            />
          </div>
          <div className={classes.galleryItem}>
            <img

              src={"gallary/6.jpg"}
              alt="Gallery 6"
              className={classes.galleryImage}
            />
          </div>
          <div className={classes.galleryItem}>
            <img

              src={"gallary/7.jpg"}
              alt="Gallery 7"
              className={classes.galleryImage}
            />
          </div>
          <div className={classes.galleryItem}>
            <img

              src={"gallary/8.PNG"}
              alt="Gallery 8"
              className={classes.galleryImage}
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;
