import React, { useEffect, useState } from "react";
import {
  TextField,
  Slider,
  Typography,
  Container,
  Grid,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ButtonBlue from "../Custom/ButtonBlue";
import ReportModal from "./ReportModal";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  calculatorContainer: {
    display: "flex",
    boxShadow: "none",
    justifyContent: "center",
    alignItems: "center",
    background: "#ffffff",
    padding: "40px 0px",
    maxWidth: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  shiningText: {
    color: "#1976d2",
    fontWeight: "bold",
    fontFamily: "sans-serif",
    textAlign: "center",
    position: "relative",
    fontSize: "1.8rem",
    marginBottom: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      background:
        "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent)",
      backgroundSize: "200px 100%",
      animation: "$shine 1.5s infinite",
    },
  },
  "@keyframes shine": {
    "0%": {
      backgroundPosition: "-200px",
    },
    "100%": {
      backgroundPosition: "200px",
    },
  },
  resultsContainer: {
    height: "100%",
    padding: "20px",
    background: "#e3f2fd",
    borderRadius: "8px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlignLast: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      padding: "10px",
    },
  },
  resultItem: {
    marginBottom: "10px",
    padding: "10px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    width: "100%",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      padding: "8px",
      marginBottom: "8px",
    },
  },
  resultText: {
    color: "black",
    padding: 10,
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "0.9rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  separator: {
    width: "100%",
    height: "1px",
    backgroundColor: "#ccc",
    margin: "10px 0",
  },
  resultTitle: {
    fontSize: "1.5rem",
    fontWeight: "bolder",
    color: "#1976d2",
  },
  resultValue: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#1976d2",
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
  },
  value: {
    color: "black",
    padding: 10,
    fontWeight: "bold",
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
}));

const InvestmentCalculator = ({ productType, product, onCalculate }) => {
  const classes = useStyles();
  const [principal, setPrincipal] = useState(100000);
  const [monthlyDeposit, setMonthlyDeposit] = useState(5000);
  const [interestRate, setInterestRate] = useState(6);
  const [depositMonths, setDepositMonths] = useState(12);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (productType === "Recurring Deposit") {
      setPrincipal(monthlyDeposit * depositMonths);
    }
  }, [productType, monthlyDeposit, interestRate, depositMonths]);

  const interestRates = {
    "Fixed Deposit": [8, 10, 12],
    "Monthly Income Scheme": [7.5, 9.5, 11.5],
    "Recurring Deposit": 9,
    "Daily Deposit": 5,
  };

  // DEFINE THE INTEREST RATE BUCKET FOR EACH PRODUCT TYPE
  const getInterestRate = (product, tenure) => {
    if (product === "Recurring Deposit" || product === "Daily Deposit") {
      return interestRates[product];
    }

    const rates = interestRates[product];
    if (!rates) return null;

    if (tenure <= 12) {
      return rates[0];
    } else if (tenure > 12 && tenure <= 24) {
      return rates[1];
    } else if (tenure > 24) {
      return rates[2];
    }

    return null;
  };

  // useEffect to calculate the interest rate based on tenure change for the selected product
  useEffect(() => {
    const interestRate = getInterestRate(product, depositMonths);
    setInterestRate(interestRate);
  }, [depositMonths, product]);

  useEffect(() => {
    if (
      isNaN(principal) ||
      isNaN(monthlyDeposit) ||
      isNaN(interestRate) ||
      isNaN(depositMonths)
    ) {
      return;
    }

    let maturityAmount = 0;
    if (productType === "Fixed Deposit") {
      maturityAmount = calculateFixedDepositMaturityAmount(
        principal,
        interestRate,
        depositMonths
      );
    } else if (productType === "Monthly Income Scheme") {
      maturityAmount = calculateMonthlySchemeMaturityAmount(
        principal,
        interestRate,
        depositMonths
      );
    } else if (productType === "Recurring Deposit") {
      maturityAmount = calculateRecurringDepositMaturityAmount(
        monthlyDeposit,
        interestRate,
        depositMonths
      );
    } else if (productType === "Daily Deposit") {
      maturityAmount = calculateDailyDepositMaturityAmount(
        principal,
        interestRate,
        depositMonths
      )
    }
    const interestEarned =
      maturityAmount -
      (productType === "Recurring Deposit"
        ? monthlyDeposit * depositMonths
        : principal);
    if(productType !== "Daily Deposit")  {
      onCalculate(
        productType === "Recurring Deposit"
          ? monthlyDeposit * depositMonths
          : principal,
        interestEarned
      );
    }
    
  }, [
    principal,
    monthlyDeposit,
    interestRate,
    depositMonths,
    productType,
    onCalculate,
  ]);

  const calculateFixedDepositMaturityAmount = (
    principal,
    annualRate,
    months
  ) => {
    let amount = principal;

    // calculate the interest earned and add into the principal amount
    const monthlyInterestRate = annualRate / (12 * 100);

    for (let i = 0; i < months; i++) {
      let interestEarned = principal * monthlyInterestRate;
      amount += interestEarned;
    }

    return amount.toFixed(2);
  };

  const calculateMonthlySchemeMaturityAmount = (
    principal,
    annualRate,
    months
  ) => {
    let amount = principal;

    // calculate the interest earned and add into the principal amount
    const monthlyInterestRate = annualRate / (12 * 100);

    for (let i = 0; i < months; i++) {
      let interestEarned = principal * monthlyInterestRate;
      amount += interestEarned;
    }

    return amount.toFixed(2);
  };

  const calculateRecurringDepositMaturityAmount = (
    monthlyDeposit,
    annualRate,
    months
  ) => {
    let principal = monthlyDeposit * months;

    const monthlyInterestRate = annualRate / (12 * 100);
    let maturityAmount = 0;

    for (let i = 0; i < months; i++) {
      // Each deposit is compounded for (months - i) periods
      maturityAmount = principal * monthlyInterestRate;
    }

    maturityAmount = principal + maturityAmount * depositMonths;

    return maturityAmount.toFixed(2);
  };

  const navigate = useNavigate();

  const calculateDailyDepositMaturityAmount =(dailyDeposit, annualInterestRate, depositMonths) => {
    const monthlyInterestRate = annualInterestRate / (12 * 100);
    let cummulativeInterest = 0;
    let monthlyDeposit = dailyDeposit * 365 / 12;
    let totalPrincipal = monthlyDeposit * depositMonths;
    for(let i = 0; i < depositMonths; i++) {
      cummulativeInterest += monthlyDeposit * monthlyInterestRate;
    }
    const maturityAmount = totalPrincipal + cummulativeInterest;
    onCalculate(totalPrincipal, cummulativeInterest)

    return maturityAmount.toFixed(2);
  }

  const maturityAmount =
    productType === "Fixed Deposit"
      ? calculateFixedDepositMaturityAmount(
          principal,
          interestRate,
          depositMonths
        )
      : productType === "Monthly Income Scheme"
      ? calculateMonthlySchemeMaturityAmount(
          principal,
          interestRate,
          depositMonths
        )
      : productType === "Recurring Deposit"
      ? calculateRecurringDepositMaturityAmount(
          monthlyDeposit,
          interestRate,
          depositMonths
        )
        : productType === "Daily Deposit"
        ? calculateDailyDepositMaturityAmount(
            principal,
            interestRate,
            depositMonths
          ) : 0

  let interestEarned = (
    maturityAmount -
    (productType === "Recurring Deposit"
      ? monthlyDeposit * depositMonths
      : principal)
  ).toFixed(2);

  let totalPrincipalForDD = 0;
  if(productType === "Daily Deposit") {
    const monthlyInterestRate = interestRate / (12 * 100);
    let cummulativeInterest = 0;
    let monthlyDeposit = principal * 365 / 12;
    for(let i = 0; i < depositMonths; i++) {
      cummulativeInterest += monthlyDeposit * monthlyInterestRate;
    }
    interestEarned = cummulativeInterest.toFixed(2);
    totalPrincipalForDD = monthlyDeposit * depositMonths;

  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const reportProps = {
    productType,
    principal,
    monthlyDeposit,
    interestRate,
    depositMonths,
  };

  return (
    <Container maxWidth="100%" className={classes.calculatorContainer}>
      {/* <Box className={classes.calculator}> */}
        <Grid container gap={6} spacing={2}>
          <Grid item xs={12} lg={5.5}>
            <p className={classes.shiningText}>{product}</p>
            <Grid container spacing={2}>
              {productType !== "Recurring Deposit" && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      label={productType === "Daily Deposit" ? 'Daily Amount' : "Principal Amount"}
                      type="number"
                      value={principal?.toString().replace(/^0+/, "")}
                      inputProps={{
                        min: 500,
                        max: 1000000,
                      }}
                      onChange={(e) => {
                        setPrincipal(
                          Math.max(
                            0,
                            Math.min(
                              1000000,
                              parseFloat(e.target.value.replace(/^0+/, "")) ||
                                0 ||
                                0
                            )
                          )
                        );
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.sliderContainer}>
                      <Slider
                        value={principal?.toString().replace(/^0+/, "")}
                        onChange={(e, newValue) =>
                          setPrincipal(
                            Math.max(
                              10000,
                              Math.min(1000000, parseFloat(newValue) || 10000)
                            )
                          )
                        }
                        min={10000}
                        max={1000000}
                        step={1000}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                </>
              )}
              {productType === "Recurring Deposit" && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      label="Monthly Deposit Amount"
                      type="number"
                      value={monthlyDeposit?.toString().replace(/^0+/, "")}
                      inputProps={{
                        min: 500,
                        max: 1000000,
                      }}
                      onChange={(e) => {
                        setMonthlyDeposit(
                          Math.max(
                            0,
                            Math.min(
                              1000000,
                              parseFloat(e.target.value.replace(/^0+/, "")) ||
                                0 ||
                                0
                            )
                          )
                        );
                        setPrincipal(monthlyDeposit * depositMonths);
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.sliderContainer}>
                      <Slider
                        value={monthlyDeposit}
                        onChange={(e, newValue) =>
                          setMonthlyDeposit(
                            Math.max(
                              500,
                              Math.min(1000000, parseFloat(newValue) || 500)
                            )
                          )
                        }
                        min={500}
                        max={1000000}
                        step={500}
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  label="Interest Rate (%)"
                  type="number"
                  value={interestRate}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.sliderContainer}>
                  <Slider
                    value={interestRate}
                    disabled
                    min={1}
                    max={15}
                    step={0.1}
                    valueLabelDisplay="auto"
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Deposit Period (Months)"
                  type="number"
                  value={depositMonths?.toString().replace(/^0+/, "")}
                  onChange={(e) =>
                    setDepositMonths(
                      Math.max(
                        0,
                        Math.min(
                          120,
                          Number(e.target.value.replace(/^0+/, "")) || 0
                        )
                      )
                    )
                  }
                  fullWidth
                  variant="outlined"
                  inputProps={{ min: 6, max: 120 }}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.sliderContainer}>
                  <Slider
                    value={depositMonths?.toString().replace(/^0+/, "")}
                    onChange={(e, newValue) =>
                      setDepositMonths(
                        Math.max(0, Math.min(120, Number(newValue)) || 0)
                      )
                    }
                    min={6}
                    max={120}
                    step={1}
                    valueLabelDisplay="auto"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5.5}>
            <Box className={classes.resultsContainer}>
              <div
                className={classes.resultItem}
                style={{ marginBottom: 10, textAlign: "center" }}
              >
                <Typography
                  fontSize={16}
                  fontWeight={"bolder"}
                  className={classes.resultTitle}
                >
                  Maturity Amount:{" "}
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={"700"}
                  className={classes.resultValue}
                >
                  ₹{parseInt(maturityAmount).toLocaleString()}
                </Typography>
              </div>
              <div className={classes.item}>
                <Typography className={classes.resultText}>
                  Interest Earned:{" "}
                </Typography>
                <span className={classes.value}>
                  ₹{parseInt(interestEarned).toLocaleString()}
                </span>
              </div>

              <div className={classes.separator}></div>
              <div className={classes.item}>
                <Typography className={classes.resultText}>
                  Total Principal:{" "}
                </Typography>
                <span className={classes.value}>
                  ₹
                  {productType === "Recurring Deposit"
                    ? parseInt(monthlyDeposit * depositMonths).toLocaleString()
                    : productType === "Daily Deposit" ? parseInt(totalPrincipalForDD.toFixed(2)).toLocaleString() :  parseInt(principal).toLocaleString()}
                </span>
              </div>
              <div className={classes.separator}></div>
              <div style={{ margin: "10px 0" }}>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 10 }}
                >
                  <ButtonBlue name={"Invest Now"} onClick={()=>{
                    navigate("/profile")
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}/>
                  <ButtonBlue
                    name={"Show Schedule"}
                    onClick={handleOpenModal}
                  />
                </div>
                <ReportModal
                  isOpen={isModalOpen}
                  onRequestClose={handleCloseModal}
                  reportProps={reportProps}
                  setIsModalOpen={setIsModalOpen}
                  isInvestment={true}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      {/* </Box> */}
    </Container>
  );
};

export default InvestmentCalculator;
